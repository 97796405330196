import React, { FunctionComponent, MouseEventHandler } from "react"
import classNames from "classnames"
import { Icon, IconVariant } from "../icon/icon"
import styles from "./button.module.scss"
import { Link } from "@components/link/link"

export type ButtonVariant = "primary" | "secondary" | "alternative"

export type ButtonSize = "xs" | "sm" | "md" | "lg"

export interface ButtonProps {
  disabled?: boolean
  className?: string
  icon?: IconVariant
  iconOnTheRight?: boolean
  size?: ButtonSize
  tabIndex?: number
  to?: string
  target?: string
  variant?: ButtonVariant
  onClick?: MouseEventHandler
  title?: string
  tag?: any
  type?: string
  ariaLabel?: string
}

export const Button: FunctionComponent<ButtonProps> = ({
  children,
  className,
  disabled = false,
  icon,
  iconOnTheRight = false,
  size = "md",
  tabIndex,
  to = "",
  variant = "primary",
  onClick,
  title,
  tag,
  ariaLabel: ariaLabelProps,
  ...rest
}) => {
  // if tag prop exist force this tag
  let Tag

  if (tag) {
    Tag = tag
  } else if (to && (to.includes("#") || to.includes("?"))) {
    Tag = "a"
  } else if (to) {
    Tag = Link
  } else {
    Tag = "button"
  }

  let ariaLabel = undefined

  if (ariaLabelProps) {
    ariaLabel = ariaLabelProps
  } else {
    if (children) {
      ariaLabel = children.toString()
    } else if (icon) {
      ariaLabel = icon.replace(/\d+-/, "")
    }
  }

  return (
    <Tag
      aria-label={ariaLabel}
      className={classNames(
        styles.button,
        styles[size],
        styles[variant],
        {
          [styles.disabled]: disabled,
          [styles.icon]: Boolean(icon && !children),
          [styles.text]: Boolean(children),
          [styles.iconOnTheRight]: iconOnTheRight
        },
        className
      )}
      title={title}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      to={to}
      href={to}
      {...rest}
    >
      {icon && (
        <Icon
          className={classNames(styles.icon, icon)}
          disabled={disabled}
          variant={icon}
        />
      )}
      {children}
    </Tag>
  )
}
