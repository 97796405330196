import React, { FunctionComponent, MouseEventHandler } from "react"
import { Link as GatsbyLink, GatsbyLinkProps } from "gatsby"
import { externalLink } from "../../core/constants"

export interface LinkProps extends GatsbyLinkProps<never> {
  className?: string
  alt?: string
  onClick?: MouseEventHandler
}

export const Link: FunctionComponent<LinkProps> = ({
  children,
  to,
  onClick,
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  ref,
  ...props
}) => {
  const isExternal =
    /^http/.test(to) || /^\/store/.test(to) || /^\/learn/.test(to)
  const isSpecial = /^(tel:|mailto:)/.test(to)
  const isStoreLigonierOrg =
    /^https:\/\/store\.ligonier\.org/.test(to) ||
    /^store\.ligonier\.org/.test(to) ||
    /^\/store/.test(to)
  const isLearnLigonierOrg =
    /^https:\/\/learn\.ligonier\.org/.test(to) ||
    /^learn\.ligonier\.org/.test(to) ||
    /^\/learn/.test(to)

  if (isExternal) {
    const targetAttribute =
      isStoreLigonierOrg || isLearnLigonierOrg
        ? { target: "_self" }
        : { target: "_blank" }

    return (
      <a href={to} {...externalLink} {...targetAttribute} {...props}>
        {children}
      </a>
    )
  }

  if (isSpecial) {
    return (
      <a href={to} {...props}>
        {children}
      </a>
    )
  }

  if (onClick && !to) {
    return (
      <a onClick={onClick} {...props}>
        {children}
      </a>
    )
  }

  const path = to

  const gatsbyLinkProps = { ...props }

  return (
    <GatsbyLink {...gatsbyLinkProps} to={path} onClick={onClick}>
      {children}
    </GatsbyLink>
  )
}
