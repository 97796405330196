import { parse } from "cookie"
import React from "react"
import { assign, createMachine } from "xstate"
import { toast } from "react-hot-toast"
import { Link } from "@components/link/link"
import { cartCreate, getCart, addLineItem } from "@utils/shopifyCart"
import { CustomAttribute } from "@utils/shopify-buy"
import { StoreCart } from "@core/constants"
import { getIDfromGID } from "@utils/getIDfromGID"

type EventData = {
  cartId?: string
  variantId?: string
  quantity?: number
  customAttributes?: Array<CustomAttribute>
  itemCount?: number
}

export type Events =
  | { type: "CART_ID_FOUND"; data: EventData }
  | { type: "CART_ID_NOT_FOUND"; data: EventData }
  | { type: "ADD_LINE_ITEM"; data: EventData }

export type Context = {
  cartId: string | null
  totalItems: number
  itemsBeingAdded: Array<string>
  retries: number
}

const newCartMachine = createMachine<Context, Events>(
  {
    id: "newCart",
    initial: "checkingForCartId",
    context: {
      cartId: null,
      totalItems: 0,
      itemsBeingAdded: [],
      retries: 0
    },
    states: {
      checkingForCartId: {
        invoke: {
          src: "getCartId"
        },
        on: {
          CART_ID_FOUND: {
            target: "fetchingCart",
            actions: "saveCartId"
          },
          CART_ID_NOT_FOUND: "creatingCart"
        }
      },
      creatingCart: {
        invoke: {
          src: "createCart",
          onDone: {
            target: "idle",
            actions: "saveCartId"
          },
          onError: [
            {
              target: "creatingCart",
              cond: "canRetry",
              actions: ["increaseRetries"]
            },
            {
              target: "failure",
              actions: ["clearRetries"]
            }
          ]
        }
      },
      fetchingCart: {
        invoke: {
          src: "getCart",
          onDone: {
            target: "idle",
            actions: "increaseTotalItems"
          },
          onError: [
            {
              target: "fetchingCart",
              cond: "canRetry",
              actions: ["increaseRetries"]
            },
            {
              target: "creatingCart",
              actions: ["clearRetries"]
            }
          ]
        }
      },
      idle: {
        on: {
          ADD_LINE_ITEM: "addingLineItem"
        }
      },
      addingLineItem: {
        entry: "addIdToItemsBeingAdded",
        invoke: {
          src: "addLineItem",
          onDone: {
            target: "idle",
            actions: ["increaseTotalItems", "clearItemsBeingAdded"]
          },
          onError: {
            target: "idle",
            actions: "clearItemsBeingAdded"
          }
        }
      },
      failure: {
        type: "final"
      }
    }
  },
  {
    services: {
      getCartId: () => (cb) => {
        const cookies = parse(document.cookie)
        const cartId = cookies.ligonierCart

        if (cartId) {
          cb({
            type: "CART_ID_FOUND",
            data: { cartId }
          })
        } else {
          cb({
            type: "CART_ID_NOT_FOUND"
          })
        }
      },
      getCart: async (ctx) => {
        const { cartId } = ctx
        const { cart } = await getCart(cartId!)

        const lineItemCount = cart.lines.edges.reduce((acc, cur) => {
          return acc + cur.node.quantity
        }, 0)

        return {
          itemCount: lineItemCount
        }
      },
      addLineItem: async (ctx, event) => {
        const { cartId } = ctx
        const { variantId, quantity = 1, customAttributes } = event.data

        if (!variantId) {
          toast("Variant ID is required")
          throw new Error("Variant ID is required")
        }

        // we would never get to this state if cartId is null
        const { cartLinesAdd } = await addLineItem(cartId!, {
          merchandiseId: variantId,
          quantity,
          attributes: customAttributes
        })

        const { cart, userErrors } = cartLinesAdd

        if (userErrors && userErrors.length > 0) {
          const errorMessages = userErrors
            .map(({ message }) => message)
            .join(", ")

          toast(`Failed to add line item: ${errorMessages}`)
          throw new Error(`Failed To add line item: ${errorMessages}`)
        }

        if (cart.id) {
          const rootUrl = location.origin

          const [{ node: lineItemAdded }] = cart.lines.edges.filter(
            ({
              node: {
                merchandise: { id: lineItemId }
              }
            }) => {
              if (Array.isArray(variantId)) {
                return variantId.indexOf(lineItemId) !== -1
              } else {
                return lineItemId === variantId
              }
            }
          )

          const lineItemAddedTitle = lineItemAdded.attributes.filter(
            (attr) => attr.key === "title"
          )[0].value

          const segmentData: Record<
            string,
            number | string | undefined | Array<string | undefined> // probably could just be an unknown
          > = {
            price: Number(lineItemAdded.merchandise.priceV2.amount),
            compare_at_price: Number(
              lineItemAdded.merchandise.compareAtPriceV2.amount
            ),
            currency: "USD",
            cart_id: getIDfromGID(cart.id),
            productID: getIDfromGID(lineItemAdded.merchandise.product.id),
            productSKU: lineItemAdded.merchandise.sku,
            productCategory: lineItemAdded.merchandise.product.productType,
            productName: lineItemAddedTitle,
            productVariant: lineItemAdded.merchandise.product.format?.value,
            productURL: `${rootUrl}/${lineItemAdded.merchandise.product.handle}`,
            imageURL: lineItemAdded.merchandise.image.url,
            brand: lineItemAdded.merchandise.product.vendor
          }

          if (lineItemAdded.quantity - quantity === 0) {
            window.analytics?.track("Product Added", segmentData)
          }

          toast(
            <div>
              Item
              {Array.isArray(variantId) && variantId.length > 1 ? `s` : ``}{" "}
              added to the <Link to={StoreCart}>cart</Link>.
            </div>
          )

          return {
            itemCount: quantity
          }
        } else {
          toast("Line item could not be added")
          throw new Error("Line item could not be added")
        }
      },
      createCart: async () => {
        const cart = await cartCreate()

        return {
          cartId: cart.id
        }
      }
    },
    actions: {
      clearItemsBeingAdded: assign((_) => ({
        itemsBeingAdded: []
      })),
      addIdToItemsBeingAdded: assign((ctx, event) => {
        const { itemsBeingAdded } = ctx
        const { variantId } = event.data

        if (variantId) {
          return {
            itemsBeingAdded: [...itemsBeingAdded, variantId]
          }
        }

        return ctx
      }),
      increaseTotalItems: assign((ctx, event) => ({
        totalItems: ctx.totalItems + (event.data.itemCount || 0)
      })),
      saveCartId: assign((_, event) => {
        const hostName = location.hostname

        const domain = hostName.substring(
          hostName.lastIndexOf(".", hostName.lastIndexOf(".") - 1) + 1
        )

        if (domain.indexOf("localhost") !== -1) {
          document.cookie = `ligonierCart=${event.data.cartId};path=/`
        } else {
          document.cookie = `ligonierCart=${event.data.cartId};domain=${domain};path=/`
        }

        return {
          cartId: event.data.cartId
        }
      }),
      increaseRetries: assign({ retries: (context) => (context.retries += 1) }),
      clearRetries: assign((_) => ({ retries: 0 }))
    },
    guards: {
      canRetry: (context) => context.retries < 1 // retry 3 times
    }
  }
)

export default newCartMachine
