import React, { FunctionComponent, ReactNode, useEffect } from "react"
import { Toaster } from "react-hot-toast"
import { QueryClient, QueryClientProvider } from "react-query"
import { ReactQueryDevtools } from "react-query/devtools"
import { enableES5 } from "immer"
import { RecentlyViewedProvider } from "../../contexts/recentlyViewedContext"
import { StoreProvider } from "../../features/store/store-provider"
import { useIE } from "../../hooks/use-ie"
import { NewCartProvider } from "@contexts/newCartContext"
import "./app.scss"
import { AlertBar } from "@components/AlertBar"

enableES5()

interface AppComponentProps {
  children: React.ReactNode
  disableAuth?: boolean
}
const queryClient = new QueryClient()

export const App: FunctionComponent<AppComponentProps> = ({ children }) => {
  useIE()

  return (
    <>
      <RecentlyViewedProvider>
        <NewCartProvider>
          <QueryClientProvider client={queryClient}>
            <StoreProvider>
              <AlertBar />
              {children}
            </StoreProvider>
            {process.env.GATSBY_ACTIVE_ENV === "development" ? (
              <ReactQueryDevtools initialIsOpen={false} />
            ) : null}
          </QueryClientProvider>
        </NewCartProvider>
      </RecentlyViewedProvider>
      <Toaster reverseOrder={true} />
    </>
  )
}

export interface AppWrapperProps {
  element: ReactNode
}

export const AppWrapper: FunctionComponent<AppWrapperProps> = ({ element }) => (
  <App>{element}</App>
)

export default AppWrapper
